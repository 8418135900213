@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.container {
    width: 90%;
    /* height: 100%; */
    max-width: 1170px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: 60px;
    /* padding: 35px 0; */
    margin: 0 auto; /* Center the container */
}

.container * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container body {
    min-height: 100vh; /* Set a minimum height for the container */
    display: grid;
    place-items: center;
    font-family: 'Poppins', sans-serif; /* Font family for content */
}

.contentLeft,
.contentRight {
    width: 100%;
}

.contentLeft .row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.contentLeft .row .imgWrapper {
    width: 100%;
    height: 450px;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.15);
}

.contentLeft .row .imgWrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    transition: 0.3s ease;
}

.contentLeft .row .imgWrapper:hover img {
    transform: scale(1.5);
}

.contentLeft .row .imgWrapper:nth-child(odd) {
    transform: translateY(-20px);
}

.contentLeft .row .imgWrapper:nth-child(even) {
    transform: translateY(20px);
}

.contentRight .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.contentRight .content h4 {
    font-size: 22px;
    font-weight: 400;
    color: #00a01d;
}

.contentRight .content h2 {
    font-size: 40px;
    color: #1e272e;
}

.contentRight .content p {
    font-size: 16px;
    color: #343434;
    line-height: 28px;
    padding-bottom: 10px;
}

.contentRight .content a {
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 13px 30px;
    color: #fff;
    background: #00a01d;
    border-radius: 8px;
    user-select: none;
}

@media (max-width: 768px) {
    .container {
        grid-template-columns: 1fr;
    }
    .contentLeft .row {
        grid-template-columns: repeat(2, 1fr);
    }
    .contentLeft .row .imgWrapper {
        height: 150px;
    }
    .contentRight .content h4 {
        font-size: 18px;
    }
    .contentRight .content h2 {
        font-size: 30px;
    }
}
.read-more-button {
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 10px 20px;
  color: #fff;
  background: #00a01d; /* Matches your existing button style */
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
  user-select: none; /* Prevents text selection */
}

.read-more-button:hover {
  background: #00a01d; /* Slightly lighter color on hover */
}

section.about-us-section {
  padding: 60px 0;
  /* background-image: url('https://images.unsplash.com/photo-1631052941794-2a6e26d4ac17?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: center; */
  /* min-height: 100%; */
}

h1.text-xl.font-semibold.text-gray-800.mb-4 {
    margin-left: 346px;
    margin-top: 14px;
}
h1.text-xl.font-semibold.text-gray-800.mb-4 {
    font-size: 42px;
}
h1.text-xl.font-semibold.text-gray-800.mb-4 {
    margin-left: 346px;
    margin-top: 72px;
    font-size: 42px;
}
h2.opportunities {
    margin-left: 32px;
    font-size: 40px;
    color: #1e272e;
}
p.opportunitiesParagraphs {
    margin-left: 32px;
}
.contentRights {
    display: flex; /* Use flexbox for layout */
    align-items: flex-start; /* Align items at the top */
  }
  
  .contents {
    flex: 1; /* Allow contents to take available space */
    display: flex;
    flex-direction: column;
    margin-top: 52px;
  }
  
  .rightImage {
    margin-left: 20px; /* Space between text and image */
    max-width: 100%; /* Responsive image */
    height: auto; /* Maintain aspect ratio */
  }

  /* img.rightImage {
    height: 606px;
    margin-right: 78px;
    border-radius: 11px;
    margin-top: 70px;
} */

img.rightImage {
    height: 449px;
    margin-right: 73px;
    margin-top: 111px;
    /* border: revert-layer; */
    border-radius: 28px;
    /* width: 685px; */
}
  
