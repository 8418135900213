/* Existing styles */
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Hide scrollbar for all elements */
::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

body {
  height: 100vh; /* Full viewport height */
  overflow-y: scroll; /* Allow vertical scrolling */
  margin: 0; /* Remove default margin */
}



/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;
