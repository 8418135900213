.contact-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
  }
  
  .contact-form-container {
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .input-field {
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
  
  .textarea-field {
    resize: none; /* Prevent resizing */
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .contact-form-container {
      padding: 15px;
    }
  }
  

  .Contactussection-background{
    background-image: url('../../components/Site Context/Contact US/pexels-nic-scrollstoppingphotos-28101093.jpg');
    background-size: cover;
    background-position: center;
    min-height: 700px; /* Use min-height */
  }

  .input-white {
    color: white; /* Text color */
    caret-color: white; /* Cursor color */
  }
  