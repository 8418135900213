.signup-form {
  margin: 20px;
  padding: 20px;
  border: 2px solid #ff69b4; /* Hot pink border */
  border-radius: 12px;
  background-color: #fff3f5; /* Soft pink background */
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.signup-form h2 {
  margin-bottom: 15px;
  color: #ff1493; /* Deep pink */
}

.signup-form input {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ff69b4;
  border-radius: 6px;
  transition: border-color 0.3s;
}

.signup-form input:focus {
  border-color: #ff1493; /* Change border color on focus */
  outline: none;
}

.signup-form button {
  padding: 12px;
  background-color: #ff1493; /* Deep pink */
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.signup-form button:hover {
  background-color: #ff69b4; /* Hot pink on hover */
}

.info-section {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white */
  border-radius: 12px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}
body{
  margin: 0;
}

.glow-effect {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 255, 255, 0.5), 0 0 30px rgba(0, 255, 255, 0.5);
}


/* In your CSS file */
.background-image {
  background-image: url('../../Images/Homepage drone image.jpg');
  background-size: cover;
  background-position: center center;
  height: 700px;
  background-color: gray;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
